import {
    faArrowRightArrowLeft,
    faMinus,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Box,
    Button,
    Card,
    Container,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import React, { memo, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./headerForm.css";
import CustomDatePicker from "./date-picker/datePicker";
import CustomTimePicker from "./time-picker/CustomTimePicker";
import MoreOptionsForms from "./more-options/moreOptionsForms";
import SearchPlaces from "./search-places/searchPlaces";
import { useForm } from "react-hook-form";
import moment from "moment";
import PostRequest from "../services/PostRequest";
import GetRequest from "../services/GetRequest";
import CONFIG from "../config/config.json";

const CircleBox = (props) => {
    const { alphabets, background = "#bbb", style = {} } = props;

    return (
        <div
            style={{ backgroundColor: background, ...style }}
            className="alphabets-box"
        >
            <span className="alphabets">{alphabets}</span>
        </div>
    );
};

const MAXIUMN_LIMIT_CALL_API = CONFIG.MAXIUM_LIMIT_TO_CALL_API;
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography variant="span">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const HeaderForm = (props) => {
    const {
        handleLoader,
        handlePlanTripData,
        isRequestForData,
        userInputsHandler,
    } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
        noSsr: true,
    });

    const [localUI, setLocalUI] = useState({
        showArrival: true,
        showVia: true,
    });
    const [value, setValue] = useState(0);
    const [isMoreOptions, setIsMoreOptions] = useState(false);
    const [daptureDate, setDaptureDate] = useState(new Date());
    const [arrivalDate, setArrivalDate] = useState(new Date());
    const [daptureTime, setDaptureTime] = useState(new Date());
    const [arrivalTime, setArrivalTime] = useState(new Date());
    const [destinationFrom, setDestinationFrom] = useState({
        lat: "",
        lng: "",
    });
    const [destinationTo, setDestinationTo] = useState({
        lat: "",
        lng: "",
    });
    const [destinationVia, setDestinationVia] = useState({
        lat: "",
        lng: "",
    });
    const [walkingSpeed, setWalkingSpeed] = useState(0);
    const [dealyTime, setDelayTime] = useState(0);
    const [destination, setDestination] = useState(null);
    const [to, setTo] = useState(null);
    const [isSubmit, setIsSubmit] = useState(true);
    const [currentActiveFocus, setCurrentActiveFocus] = useState(0);
    const [formData, setFormData] = useState({
        firstMileMode: "WALK",
        lastMileMode: "WALK",
        stopoverTime: 0,
    });
    const { register, handleSubmit } = useForm();

    const handleMoreOptionsSection = useCallback(
        () => setIsMoreOptions(!isMoreOptions),
        [isMoreOptions]
    );
    const handleChange = useCallback((event, newValue) => {
        if (newValue === 1) {
            setLocalUI({
                ...localUI,
                showVia: false,
                showArrival: true,
            });
        } else {
            setLocalUI({
                ...localUI,
                showVia: true,
                showArrival: true,
            });
        }
        setValue(newValue);
    }, []);

    let call_api_number = 0;

    const getAuthToken = async (options) => {
        try {
            if (call_api_number <= MAXIUMN_LIMIT_CALL_API) {
                let authToken = await GetRequest();
                if (authToken) {
                    localStorage.setItem(
                        "authToken",
                        authToken.data.access_token
                    );
                    await getMapData(options);
                    return true;
                } else {
                    alert("Something went wrong, please try again later.");
                    return false;
                }
            } else {
                throw new Error("Maxiumn limit exceeded");
            }
        } catch (error) {
            handlePlanTripData(false);
            return false;
        }
    };

    const getMapData = async (requestData = {}) => {
        try {
            handlePlanTripData(false);
            handleLoader(true);

            const options = {
                dateTime: requestData.dateTime,
                isDateTimeForArrival: false,
                maxItineraries: 2,
                source: {
                    lat: requestData.source.lat.toString(),
                    lng: requestData.source.lng.toString(),
                },
                destination: {
                    lat: requestData.destination.lat.toString(),
                    lng: requestData.destination.lng.toString(),
                },
                showFares: true,

                travelModes: ["TRANSIT", "WALK"],
                showIntermediateStops: true,
                alightSlack: 120,
                boardSlack: 120,
                accessible: requestData.accessible === undefined ? true : false,
                walkingSpeed: requestData.walkingSpeed,
            };
            if (formData.firstMileMode) {
                options["firstMileMode"] = formData.firstMileMode;
            }
            if (formData.lastMileMode) {
                options["lastMileMode"] = formData.lastMileMode;
            }
            if (formData.stopoverTime) {
                options["waitTimeAtVia"] = formData.stopoverTime;
            }

            if (
                requestData.via.lat.toString() != "" &&
                requestData.via.lng.toString()
            ) {
                options["via"] = {
                    lat: requestData.via.lat.toString(),
                    lng: requestData.via.lng.toString(),
                };
            }

            let token = "";

            let checkTokenExists = localStorage.getItem("authToken");
            if (!checkTokenExists) {
                getAuthToken(requestData);
                return true;
            }

            token = checkTokenExists;

            const planData = await PostRequest(options, token);

            if (planData.status) {
                handlePlanTripData(planData ? planData.data.plan : false);
            } else {
                localStorage.removeItem("authToken");
                getMapData(options);
            }
        } catch (error) {
            call_api_number += 1;
            localStorage.removeItem("authToken");
            getAuthToken(requestData);
        } finally {
            handleLoader(false);
        }
    };

    const onSubmit = async (data) => {
        try {
            call_api_number = 1;

            isRequestForData(true);
            let dDate = moment(daptureDate["$d"]).format("DD-MM-YYYY");
            let dTime = moment(daptureTime["$d"]).format("hh:mm:ss a");

            let ADate = moment(arrivalDate["$d"]).format("DD-MM-YYYY");
            let ATime = moment(arrivalTime["$d"]).format("hh:mm:ss a");

            let dDateTime = moment(
                dDate + " " + dTime,
                "DD-MM-YYYY hh:mm:ss a"
            ).format();
            let ADataTime = moment(
                ADate + " " + ATime,
                "DD-MM-YYYY hh:mm:ss a"
            ).format();

            data.dateTime = value == 0 ? dDateTime : ADataTime;
            data.isDateTimeForArrival = value == 0 ? false : true;
            data.source = destinationFrom;
            data.destination = destinationTo;
            data.via = destinationVia;
            if (walkingSpeed > 50) {
                data.walkingSpeed = "FAST";
            } else if (walkingSpeed < 50) {
                data.walkingSpeed = "SLOW";
            } else {
                data.walkingSpeed = "NORMAL";
            }

            data.waitTimeAtVia = dealyTime["$m"];

            getMapData(data);
        } catch (error) {
            console.log("error in generateToken", error);
        }
    };

    const changeDestination = useCallback(() => {
        let storeDestination = destination;
        let storeTo = to;

        setDestination(storeTo);
        setTo(storeDestination);

        userInputsHandler({
            by: storeDestination ? storeDestination.description : "",
            destination: storeTo ? storeTo.description : "",
        });
    }, [destination, to]);

    useEffect(() => {
        if (
            destinationFrom.lat &&
            destinationFrom.lng &&
            destinationTo.lat &&
            destinationTo.lng
        ) {
            setIsSubmit(false);
        } else {
            setIsSubmit(true);
        }
        userInputsHandler({
            by: destination ? destination.description : "",
            destination: to ? to.description : "",
        });
        if (destinationVia.lat && destinationVia.lng) {
            setLocalUI({
                ...localUI,
                showArrival: false,
            });
        } else {
            setLocalUI({
                ...localUI,
                showArrival: true,
            });
        }
    }, [destinationFrom, destinationTo, destination, destinationVia, to]);
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Container>
                <Card
                    sx={{ padding: "20px", borderRadius: "10px 10px 0px 0px" }}
                >
                    <Box>
                        <Typography
                            variant="span"
                            sx={{
                                fontWeight: "bold",
                                fontSize: "1.5rem",
                            }}
                        >
                            Where do you want to go?
                        </Typography>
                    </Box>
                    <Grid container direction={"row"} className="mt-3">
                        <Grid item xs={12} sm={12} md={5} xl={5}>
                            <Box className="d-flex">
                                <Box
                                    sx={{ height: "55px" }}
                                    className="d-flex flex-column justify-content-end"
                                >
                                    <CircleBox
                                        alphabets={"A"}
                                        background={
                                            currentActiveFocus === 1
                                                ? CONFIG.PRIMARY_COLOR
                                                : "#bbb"
                                        }
                                    />
                                </Box>
                                <SearchPlaces
                                    id={"from"}
                                    getValue={setDestinationFrom}
                                    getValueString={setDestination}
                                    getDefaultString={destination}
                                    focusActive={setCurrentActiveFocus}
                                    focusId={1}
                                />
                            </Box>
                        </Grid>
                        <Grid
                            sx={{ cursor: "pointer" }}
                            item
                            xs={12}
                            sm={12}
                            md={2}
                            xl={2}
                            className={"mobile-margin"}
                        >
                            <Box
                                onClick={changeDestination}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Box
                                    className="icon-bg-color"
                                    sx={{
                                        height: "40px",
                                        width: "40px",
                                        borderRadius: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faArrowRightArrowLeft}
                                        style={{
                                            transform: [{ rotateY: "90deg" }],
                                            color: "#fff",
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} xl={5}>
                            <Box className="d-flex">
                                <Box
                                    sx={{ height: "55px" }}
                                    className="d-flex flex-column justify-content-end"
                                >
                                    <CircleBox
                                        alphabets={"B"}
                                        background={
                                            currentActiveFocus === 2
                                                ? CONFIG.PRIMARY_COLOR
                                                : "#bbb"
                                        }
                                    />
                                </Box>
                                <SearchPlaces
                                    id={"to"}
                                    getValue={setDestinationTo}
                                    getValueString={setTo}
                                    getDefaultString={to}
                                    focusActive={setCurrentActiveFocus}
                                    focusId={2}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Card>
                {isMoreOptions ? (
                    <MoreOptionsForms
                        register={register}
                        via={setDestinationVia}
                        delay={setDelayTime}
                        speed={setWalkingSpeed}
                        formData={formData}
                        setFormData={setFormData}
                        localUI={localUI}
                    />
                ) : null}
                <Card
                    className="from-container"
                    sx={{ padding: "20px", borderRadius: "0px 0px 10px 10px" }}
                >
                    <Grid container>
                        <Grid item xs={12} sm={12} md={8} xl={8}>
                            <Grid container direction={"row"}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={4}
                                    xl={4}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    className="content-align"
                                >
                                    <Box
                                        sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                        }}
                                    >
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            aria-label="basic tabs example"
                                        >
                                            <Tab
                                                label="Departure"
                                                {...a11yProps(0)}
                                            />
                                            {localUI.showArrival ? (
                                                <Tab
                                                    label="Arrival"
                                                    {...a11yProps(1)}
                                                />
                                            ) : null}
                                        </Tabs>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8} xl={8}>
                                    <TabPanel value={value} index={0}>
                                        <Box
                                            className="date-time-container"
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-around",
                                                alignItems: "center",
                                                padding: 0,
                                                fontSize: "15px",
                                            }}
                                        >
                                            <CustomDatePicker
                                                label="Departure Date"
                                                name={"daptureDate"}
                                                currentValue={daptureDate}
                                                onChangeValue={setDaptureDate}
                                            />
                                            <Box sx={{ flex: 0.1 }} />
                                            <CustomTimePicker
                                                label="Departure Time"
                                                name={"daptureTime"}
                                                currentValue={daptureTime}
                                                onChangeValue={setDaptureTime}
                                            />
                                        </Box>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <Box
                                            className="date-time-container"
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-around",
                                                alignItems: "center",
                                                padding: 0,
                                            }}
                                        >
                                            <CustomDatePicker
                                                label="Arrival Date"
                                                name={"arrivalDate"}
                                                currentValue={arrivalDate}
                                                onChangeValue={setArrivalDate}
                                            />
                                            <Box sx={{ flex: 0.1 }} />
                                            <CustomTimePicker
                                                label="Arrival Time"
                                                name={"arrivalTime"}
                                                currentValue={arrivalTime}
                                                onChangeValue={setArrivalTime}
                                            />
                                        </Box>
                                    </TabPanel>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            xl={4}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Button
                                variant="text"
                                sx={{
                                    height: "20px",
                                    "&.MuiButtonBase-root:hover": {
                                        bgcolor: "transparent",
                                    },
                                }}
                                onClick={handleMoreOptionsSection}
                            >
                                {!isMoreOptions ? (
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        style={{ marginRight: 5 }}
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faMinus}
                                        style={{ marginRight: 5 }}
                                    />
                                )}
                                {!isMoreOptions ? "More" : "Fewer"} options
                            </Button>
                            {!isMobile && (
                                <Button
                                    disabled={isSubmit}
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    sx={{
                                        height: "40px",
                                        boxShadow: "none",
                                        "&.MuiButtonBase-root:hover": {
                                            boxShadow: "none",
                                        },
                                    }}
                                >
                                    Plan Trip
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Card>
            </Container>
            {isMobile && (
                <div className="footer">
                    <Button
                        disabled={isSubmit}
                        type="submit"
                        color="primary"
                        variant="contained"
                        sx={{
                            height: "40px",
                            boxShadow: "none",
                            "&.MuiButtonBase-root:hover": {
                                boxShadow: "none",
                            },
                        }}
                    >
                        Plan Trip
                    </Button>
                </div>
            )}
        </form>
    );
};

export default memo(HeaderForm);
