import { styled, Tooltip, tooltipClasses } from '@mui/material';
import React from 'react';
import './common.css';
import CONFIG from '../../config/config.json'

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: CONFIG.PRIMARY_COLOR,
        color: 'white',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

const CircleBox = (props) => {
    const { alphabets, background = "#bbb", tooltipData = "" } = props;

    return (
        <HtmlTooltip
            title={tooltipData}
        >
            <div style={{ backgroundColor: background }} className='alphabets-box'>
                <span className='alphabets'>{alphabets}</span>
            </div>
        </HtmlTooltip>
    )
}

export default CircleBox;