import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'mapbox-gl/dist/mapbox-gl.css';
import './fonts/celebes/Celebes-Black-BF64001371b9d67.ttf';
import './fonts/celebes/Celebes-Regular-BF6400136f4bb02.otf';
import './fonts/celebes/Celebes-SemiBold-BF6400136f9794c.otf';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import mapboxgl from 'mapbox-gl';
import CONFIG from '../src/config/config.json';

mapboxgl.accessToken = CONFIG.MAPBOX_ACCESS_TOKEN;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
