import React, { memo, useState } from "react";
import CircleBox from "../common/cricleBox";
import CustomSilder from "../common/customSilder";
import SearchPlaces from "../search-places/searchPlaces";
import "../headerForm.css";
//Components
import TimeSpinner from "../TimeSpinner";
import {
    Box,
    Card,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
const MoreOptionsForms = (props) => {
    const { register, via, speed, formData, setFormData, localUI } = props;
    const [viaValue, setViaValue] = useState(null);
    const transportArray = [
        {
            name: "Train",
            icon: (
                <img
                    alt="black-train"
                    className="icon-text"
                    style={{
                        height: "23px",
                        width: "23px",
                        marginRight: "5px",
                    }}
                    src={
                        require(`../../assets/icons/trainSimpleBlack.svg`)
                            .default
                    }
                />
            ),
            fromName: "isTrain",
        },
        {
            name: "Bus",
            icon: (
                <img
                    alt="black-bus"
                    className="icon-text"
                    style={{
                        height: "23px",
                        width: "23px",
                        marginRight: "5px",
                    }}
                    src={require(`../../assets/icons/busBlack.svg`).default}
                />
            ),
            fromName: "isBus",
        },
        {
            name: "Metro",
            icon: (
                <img
                    alt="black-metro"
                    className="icon-text"
                    style={{
                        height: "23px",
                        width: "23px",
                        marginRight: "5px",
                    }}
                    src={require(`../../assets/icons/metro.svg`).default}
                />
            ),
            fromName: "isMetro",
        },
        {
            name: "Tram",
            icon: (
                <img
                    alt="black-tram"
                    className="icon-text"
                    style={{
                        height: "23px",
                        width: "23px",
                        marginRight: "5px",
                        color: "black",
                    }}
                    src={require(`../../assets/icons/Tram.svg`).default}
                />
            ),
            fromName: "isTram",
        },
        {
            name: "Boat",
            icon: (
                <img
                    alt="black-boat"
                    className="icon-text"
                    style={{
                        height: "23px",
                        width: "23px",
                        marginRight: "5px",
                        color: "black",
                    }}
                    src={require(`../../assets/icons/Cruise.svg`).default}
                />
            ),
            fromName: "isBoat",
        },
    ];
    const handleFirstMileMode = (event) => {
        setFormData({
            ...formData,
            firstMileMode: event.target.value,
        });
    };
    const handleLastMileMode = (event) => {
        setFormData({
            ...formData,
            lastMileMode: event.target.value,
        });
    };
    return (
        <Card
            className="more-options-forms-bg-color"
            sx={{
                borderRadius: "0px",
                borderTop: "2px solid #f0f0f0",
                padding: "20px",
            }}
        >
            <Grid container>
                {/* COL 1 */}
                <Grid
                    className="more-options-container"
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    xl={4}
                >
                    {/* Stop over controller */}
                    {localUI.showVia ? (
                        <>
                            {" "}
                            <Typography
                                sx={{ fontWeight: "bold" }}
                                variant="span"
                            >
                                Stopover
                            </Typography>
                            <Box className="d-flex justify-content-center align-items-center mt-3">
                                <Box
                                    sx={{ height: "65px" }}
                                    className="d-flex flex-column justify-content-end"
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "bold",
                                            marginRight: 1,
                                        }}
                                        variant="span"
                                    >
                                        Via
                                    </Typography>
                                </Box>
                                <SearchPlaces
                                    id={"via"}
                                    getValue={via}
                                    getValueString={setViaValue}
                                    getDefaultString={viaValue}
                                    focusId={false}
                                    focusActive={() => {}}
                                />
                            </Box>
                            {/* stopover time picker */}
                            <Box
                                className="d-flex justify-content-start align-items-center"
                                sx={{
                                    marginTop: 2,
                                }}
                            >
                                <Typography
                                    sx={{ fontWeight: "bold", marginRight: 1 }}
                                    variant="span"
                                >
                                    Stopover time
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    marginTop: 1,
                                }}
                            >
                                <TimeSpinner
                                    value={formData.stopoverTime}
                                    onChange={(value) => {
                                        setFormData({
                                            ...formData,
                                            stopoverTime: value,
                                        });
                                    }}
                                    customStyles={{
                                        borderRadius: "10px",
                                    }}
                                />
                            </Box>
                        </>
                    ) : null}

                    {/* Walking Speed controller */}
                    <Box className={localUI.showVia ? "mt-4" : "mt-1"}>
                        <Box className="d-flex justify-content-start align-items-center">
                            <Typography
                                sx={{ fontWeight: "bold", marginRight: 1 }}
                                variant="span"
                            >
                                Walking Speed
                            </Typography>
                            <CircleBox
                                alphabets={"i"}
                                background={"#39b0db"}
                                tooltipData={
                                    <React.Fragment>
                                        <Typography variant="span">
                                            Indicate your walking pace and we
                                            will give you tailor-made advice.
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </Box>
                        <Box className="mt-2" sx={{ paddingInline: 1 }}>
                            <CustomSilder speedValue={speed} />
                        </Box>
                    </Box>
                </Grid>
                {/* COL 2 */}
                <Grid
                    className="more-options-container"
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    xl={4}
                >
                    <Box className="d-flex justify-content-start align-items-center">
                        <Typography
                            sx={{ fontWeight: "bold", marginRight: 1 }}
                            variant="span"
                        >
                            Mobility
                        </Typography>
                        <CircleBox
                            alphabets={"i"}
                            background={"#39b0db"}
                            tooltipData={
                                <React.Fragment>
                                    <Typography variant="span">
                                        If you travel with an aid, you will
                                        receive travel advice that allows this
                                        aid to travel with you. Do you want the
                                        driver to be aware of your arrival?
                                        Report it to our Customer Service.
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                    </Box>
                    <Box
                        sx={{
                            borderRadius: "10px",
                            paddingInline: 2,
                            marginTop: 1,
                            bgcolor: "white",
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    {...register("accessible")}
                                    variant="outlined"
                                />
                            }
                            label={
                                <Box className="d-flex justify-content-center align-items-center font-12">
                                    <img
                                        alt="wheel-chair"
                                        className="icon-text"
                                        style={{
                                            height: "23px",
                                            width: "23px",
                                            marginRight: "5px",
                                        }}
                                        src={
                                            require(`../../assets/icons/WheelChair.svg`)
                                                .default
                                        }
                                    />
                                    WheelChair
                                </Box>
                            }
                        />
                    </Box>
                    <Box
                        className="d-flex justify-content-start align-items-center"
                        sx={{
                            marginTop: 2,
                        }}
                    >
                        <Typography
                            sx={{ fontWeight: "bold", marginRight: 1 }}
                            variant="span"
                        >
                            First Mile Mode
                        </Typography>
                    </Box>
                    <FormControl
                        fullWidth
                        sx={{
                            marginTop: 1,
                        }}
                    >
                        <Select
                            value={formData.firstMileMode}
                            onChange={handleFirstMileMode}
                        >
                            <MenuItem value={"CAR_PARK"}>Car Park</MenuItem>
                            <MenuItem value={"CAR"}>Car</MenuItem>
                            <MenuItem value={"WALK"}>Walk</MenuItem>
                            <MenuItem value={"BICYCLE"}>Bicycle</MenuItem>
                        </Select>
                    </FormControl>
                    {/* LAST MILE MODE */}
                    <Box
                        className="d-flex justify-content-start align-items-center"
                        sx={{
                            marginTop: 2,
                        }}
                    >
                        <Typography
                            sx={{ fontWeight: "bold", marginRight: 1 }}
                            variant="span"
                        >
                            Last Mile Mode
                        </Typography>
                    </Box>
                    <FormControl
                        fullWidth
                        sx={{
                            marginTop: 1,
                        }}
                    >
                        <Select
                            value={formData.lastMileMode}
                            onChange={handleLastMileMode}
                            placeholder="Mode"
                        >
                            <MenuItem value={"CAR"}>Car</MenuItem>
                            <MenuItem value={"WALK"}>Walk</MenuItem>
                            <MenuItem value={"BICYCLE"}>Bicycle</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {/* COL 3 */}
                <Grid
                    className="more-options-container"
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    xl={4}
                >
                    <Box className="mb-2">
                        <Typography
                            className="mb-4"
                            sx={{ fontWeight: "bold" }}
                            variant="span"
                        >
                            Mode of transport
                        </Typography>
                    </Box>
                    <Grid
                        container
                        gap={2}
                        sx={{ justifyContent: "space-between" }}
                    >
                        {transportArray.map((transport, i) => (
                            <Grid
                                key={"transport" + transport.name}
                                item
                                xs={5}
                                sm={5}
                                md={5}
                                xl={5}
                            >
                                <Box
                                    sx={{
                                        borderRadius: "10px",
                                        paddingInline: 2,
                                        width: "100%",
                                        bgcolor: "white",
                                    }}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                defaultChecked
                                                {...register(
                                                    transport.fromName
                                                )}
                                                variant="outlined"
                                                color="primary"
                                            />
                                        }
                                        label={
                                            <Box className="d-flex justify-content-center align-items-center font-12">
                                                {transport.icon}
                                                {transport.name}
                                            </Box>
                                        }
                                    />
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

export default memo(MoreOptionsForms);
