import {
    AppBar,
    Box,
    Container,
    createTheme,
    IconButton,
    ThemeProvider,
    Toolbar,
    Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Footer from "../componets/footer/footer";
import HeaderForm from "../componets/headerForm";
import TripRoutes from "../componets/trip-routes/tripRoutes";
import CONFIG from "../config/config.json";
import "./home.css";

const Home = () => {
    const [isLoader, setIsLoader] = useState(false);
    const [planTrip, setPlanTrip] = useState(false);
    const [isRequestData, setIsRequestData] = useState(false);
    const [userInputs, setUserInputs] = useState({
        by: "",
        destination: "",
    });

    const handleLoaderChange = useCallback((value) => {
        setIsLoader(value);
    }, []);
    const handleTripData = useCallback((value) => {
        setPlanTrip(value);
    }, []);
    const handlePlaceHolder = useCallback((value) => {
        setIsRequestData(value);
    }, []);
    const handlerUserInputs = useCallback((value) => {
        setUserInputs(value);
    }, []);

    const theme = createTheme({
        palette: {
            primary: {
                main: CONFIG.PRIMARY_COLOR,
            },
            secondary: {
                main: CONFIG.SECONDARY_COLOR,
            },
        },
        shape: {
            borderRadius: 10,
        },
        typography: {
            fontFamily: "Celebes-regular",
        },
    });

    useEffect(() => {}, [isLoader, planTrip, isRequestData]);

    return (
        <ThemeProvider theme={theme}>
            <Box
                style={{
                    backgroundImage:
                        "linear-gradient(to bottom right, #00145B, #7864FB)",
                    margin: "auto",
                }}
                className="row justify-content-center header-padding-bottom"
            >
                <AppBar
                    sx={{
                        background: "transparent",
                        border: "none",
                        boxShadow: "none",
                        width: "100%",
                        padding: 1.5,
                    }}
                    position="static"
                >
                    <Toolbar>
                        <IconButton edge="start" sx={{ mr: 1 }}>
                            <img
                                alt="compnay-logo"
                                src={require("../assets/images/Automicle-symbol.png")}
                            />
                        </IconButton>
                        <Typography
                            variant="h4"
                            component="div"
                            sx={{ flexGrow: 1, fontWeight: "bold" }}
                        >
                            Automicle
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box>
                    <HeaderForm
                        handleLoader={handleLoaderChange}
                        handlePlanTripData={handleTripData}
                        isRequestForData={handlePlaceHolder}
                        userInputsHandler={handlerUserInputs}
                    />
                </Box>
            </Box>
            {!isLoader && planTrip ? (
                <Box
                    className="row trip-routes-container justify-content-center"
                    sx={{ margin: "auto" }}
                >
                    <Container sx={{ marginBlock: 1 }}>
                        <TripRoutes
                            tripData={planTrip}
                            selectedDestination={userInputs.destination}
                            selectedFrom={userInputs.by}
                        />
                    </Container>
                </Box>
            ) : isLoader && !planTrip ? (
                <Box
                    className="d-flex flex-column align-items-center loader-container"
                    sx={{ padding: 5 }}
                >
                    <Box className="loader">
                        <img
                            alt="loader-train"
                            src={
                                require("../assets/icons/TrainSimple.svg")
                                    .default
                            }
                            style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                            }}
                        />
                    </Box>
                    <Typography
                        className="loader-text"
                        variant="span"
                        sx={{ fontSize: 20 }}
                    >
                        Travel advice is loading...
                    </Typography>
                </Box>
            ) : (
                isRequestData &&
                (!planTrip || planTrip.itineraries.length === 0) && (
                    <Box
                        className="d-flex flex-column align-items-center loader-container"
                        sx={{ padding: 5 }}
                    >
                        <Box sx={{ height: "140px", width: "140px" }}>
                            <img
                                alt="loader-primary-train"
                                src={
                                    require("../assets/icons/TrainSimple.svg")
                                        .default
                                }
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "cover",
                                }}
                            />
                        </Box>
                        <Typography
                            className="loader-text"
                            variant="span"
                            sx={{ fontSize: 20 }}
                        >
                            No Travel advice has been found.
                        </Typography>
                    </Box>
                )
            )}
            <Box
                className="row justify-content-center"
                sx={{ padding: 5, margin: "auto" }}
            >
                <Footer />
            </Box>
        </ThemeProvider>
    );
};

export default Home;
