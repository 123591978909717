import React, { useRef, useContext } from "react";
import style from "./modal.module.css";
//context
import { AppContext } from "../../AppContext";
function Modal({ children, setShowModal }) {
    const { setOpenModal } = useContext(AppContext);
    const modalRef = useRef();
    const closeModal = (e) => {
        if (modalRef.current === e.target) {
            setOpenModal(false);
            setShowModal(null);
        }
    };
    return (
        <div
            className={style.modalBackground}
            onClick={(e) => closeModal(e)}
            ref={modalRef}
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    e.stopPropagation();
                    closeModal(e);
                }
            }}
        >
            {children}
        </div>
    );
}

export default Modal;
