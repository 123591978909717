import React, { useEffect } from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import '../headerForm.css';
import { Controller, useForm } from "react-hook-form";
import dayjs from 'dayjs';
import { MobileDatePicker } from "@mui/x-date-pickers";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";

const CustomDatePicker = (props) => {
    const { control } = useForm();
    const { label, name, currentValue, onChangeValue } = props;

    useEffect(() => { }, [currentValue])
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <MobileDatePicker
                        className="date-picker"
                        label={label}
                        sx={{ flex: 0.8 }}
                        format={"DD-MM-YYYY"}
                        defaultValue={dayjs(currentValue)}
                        views={['year', 'month', 'day']}
                        componentsProps={{
                            actionBar: {
                                actions: ['accept', 'today']
                            }
                        }}
                        onChange={(event) => { onChange(event); onChangeValue(event); }}
                        slots={{
                            textField: textFieldProps => <TextField className="date-Picker-font" {...textFieldProps} InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton edge="end">
                                            <FontAwesomeIcon icon={faCalendar} style={{ fontSize: '22px' }} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                readOnly: true
                            }} />
                        }}
                    // selected={currentValue}
                    />
                </LocalizationProvider>
            )}
        >
        </Controller>
    )
}
export default CustomDatePicker;