import { Box, Typography } from '@mui/material';
import React from 'react';

const LabelIconBox = (props) => {

    const { icon, label, isBackgroud = false, bgColor = '', className = '', sytle = {} } = props;

    return (
        <Box sx={isBackgroud ? {
            paddingBlock: 0.5,
            paddingInline: 1,
            borderRadius: '10px',
            bgcolor: bgColor
        } : {}} className="d-flex justify-content-center align-items-center">
            {
                icon
            }
            <Typography className={className} variant='span' sx={{ marginLeft: '2px', ...(isBackgroud ? { color: '#fff' } : {}), ...sytle }}>{label}</Typography>
        </Box>
    )
}

export default LabelIconBox;