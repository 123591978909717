import axios from "axios";

const API_URL = "https://api.dev.automicle.com/v1/journey/plan-journey";

const PostRequest = async (data, header) => {
    const token = header;

    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };

    return new Promise((resolve, reject) => {
        axios
            .post(`${API_URL}`, data, { headers: headers, crossDomain: true })
            .then(function (response) {
                try {
                    resolve({
                        status: true,
                        data: response.data,
                    });
                } catch (error) {
                    throw new Error(error.message);
                }
            })
            .catch(function (error) {
                console.log("axois -->", error);
                reject({
                    status: false,
                    message: error.message,
                });
            });
    });
};

export default PostRequest;
