import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import { Box, Grid, Link, List, ListItem, Typography } from '@mui/material';
import moment from 'moment';
import React, { memo, useCallback, useState } from 'react';
import { secondsToMinutes } from '../../../common/common';
import CONFIG from '../../../config/config.json'

const PointsBox = (props) => {
    const { points = '' } = props;
    return (
        <Box sx={{ height: '25px', width: '25px', borderRadius: '50%', border: '2px solid #fff', bgcolor: 'primary.main' }} className="d-flex justify-content-center align-items-center">
            <Typography variant='span' sx={{ fontWeight: 'bold', fontSize: '12px', color: '#fff' }}>{points}</Typography>
        </Box>
    )
}

const TramWithMap = (props) => {

    const { style = {}, tramData = {}, isFristStep = false, isLastStep = false, oddOrEven } = props;

    const [isIntermediateStops, setIsIntermediateStops] = useState(false);

    const handleChange = useCallback(() => setIsIntermediateStops(!isIntermediateStops), [isIntermediateStops]);

    return (
        <TimelineItem sx={{ background: (oddOrEven % 2) === 1 ? '' : 'linear-gradient(90deg,#f8f8f8 90px,#f8f8f8 0)', ...style, borderRadius: '10px' }}>
            <TimelineOppositeContent className={isFristStep ? 'step-frist' : isLastStep ? 'step-last' : 'common-step'} sx={{ paddingInline: '2px', overflow: 'hidden' }} color="textSecondary">
                <Grid className={isLastStep ? "padding-top" : ''} container sx={{ height: '100%', justifyContent: 'space-between', paddingBlock: 1 }}>
                    <Grid item xs={12} sm={12} md={12} xl={12} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'start' }}>
                        <Typography className="step-font" variant='span' sx={{ fontWeight: 'bold' }}>{moment(tramData.startTime).format("HH:mm")}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingBlock: '16px' }}>
                        <Box className={"d-flex justify-content-center align-items-center flex-column"} sx={{ height: 30, width: 30 }}>
                            {/* <FontAwesomeIcon className='step-icon' icon={faTrainTram} /> */}
                            <img alt='subway' style={{ height: '100%', width: '100%', objectFit: 'contain' }} src={require('../../../assets/icons/solid/tramSolid.svg').default} />
                            {/* <Typography sx={{ fontSize: '14px' }}>28</Typography> */}
                        </Box>
                    </Grid>
                    <Grid className='routes-justify' item xs={12} sm={12} md={12} xl={12} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'end', paddingBottom: 1 }}>
                        <Typography className="step-font" variant='span' sx={{ fontWeight: 'bold' }}>{moment(tramData.endTime).format("HH:mm")}</Typography>
                    </Grid>
                </Grid>
            </TimelineOppositeContent>
            <TimelineSeparator>
                <Box sx={{ height: '20px', width: '2px', bgcolor: CONFIG.PRIMARY_COLOR }}>
                    {
                        !isFristStep &&
                        <TimelineConnector />
                    }
                </Box>
                {
                    isLastStep ?
                        <>
                            <Box>
                                <TimelineDot sx={{ margin: 0 }} color='primary' />
                            </Box>
                            <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
                            <TimelineDot sx={{ margin: 0, marginBottom: 6 }} color='primary' >
                                <PointsBox points={"B"} />
                            </TimelineDot>
                        </>
                        :
                        isFristStep ?
                            <>
                                <TimelineDot sx={{ margin: 0 }} color='primary'>
                                    <PointsBox points={"A"} />
                                </TimelineDot>
                                <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
                            </>
                            :
                            <>
                                <TimelineDot sx={{ margin: 0 }} color='primary' />
                                <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
                            </>
                }
            </TimelineSeparator>
            <TimelineContent sx={{ padding: 0, width: '100%' }}>
                <Grid container sx={{ height: '100%', justifyContent: 'space-between', padding: 1, width: '100%' }}>
                    <Grid className='d-flex justify-content-between align-items-center' item xs={12} sm={12} md={12} xl={12} sx={{ paddingBlock: 1, paddingInline: 2 }}>
                        <Typography variant='span' sx={{ fontWeight: 'bold', fontFamily: 'Celebes', fontSize: '18px' }}>{tramData.from.name}<Typography variant='span' sx={{ fontSize: '12px', opacity: 0.6, marginLeft: 2 }}>{secondsToMinutes(tramData.duration)}</Typography></Typography>
                        <Typography variant='span' sx={{ fontSize: '12px' }}></Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={12} sx={{ padding: '16px 0px 16px 16px' }}>
                        <Box className="d-flex">
                            <Box sx={{ flex: 3 }}>
                                <Typography variant='span' sx={{ fontSize: '14px', color: '#747473' }}>{tramData.route}</Typography>
                            </Box>
                            {
                                tramData.agencyName &&
                                <Box sx={{ flex: 1, textAlign: 'center' }}>
                                    <Link target="_blank" href={tramData.agencyUrl} underline="none">{tramData.agencyName}</Link>
                                </Box>
                            }
                            {
                                tramData.intermediateStops && tramData.intermediateStops.length > 0 && (
                                    <Box onClick={handleChange} className="d-flex justify-content-end" sx={{ flex: 1 }}>
                                        <Box className="d-flex justify-content-center align-items-center" sx={{ height: '20px', width: '20px', borderRadius: '50%', boxShadow: 3, bgcolor: '#fff', cursor: 'pointer' }}>
                                            <FontAwesomeIcon style={{ fontWeight: '400', fontSize: '12px', color: CONFIG.PRIMARY_COLOR }} icon={!isIntermediateStops ? faPlus : faMinus} />
                                        </Box>
                                    </Box>
                                )
                            }
                        </Box>
                        {
                            isIntermediateStops && (
                                <Box sx={{ width: '100%', marginTop: 2 }}>
                                    <List sx={{ listStyleType: 'disc', listStylePosition: 'inside', left: -45 }}>
                                        {
                                            tramData.intermediateStops.map((steps) => {
                                                return (
                                                    <ListItem sx={{ justifyContent: 'space-between' }} key={steps.name}>
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            {/* <FontAwesomeIcon style={{ marginRight: '10px' }} icon={faDotCircle} color={CONFIG.PRIMARY_COLOR} /> */}
                                                            <Box sx={{ width: '15px', height: '2px', bgcolor: "primary.main" }} />
                                                            <Typography variant='span' sx={{ fontSize: '12px', opacity: '0.6', marginLeft: 2 }}>{steps.name}</Typography>
                                                        </Box>
                                                    </ListItem>
                                                )
                                            })
                                        }
                                    </List>
                                </Box>
                            )
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={12} sx={{ paddingBlock: 2 }}>
                        <Box className="d-flex justify-content-between align-items-center" sx={{ paddingInline: 2 }}>
                            {!isLastStep ?
                                <Typography variant='span' sx={{ fontSize: '16px' }}>{tramData.to ? tramData.to.name : ''}</Typography>
                                :
                                <Box sx={{ paddingInline: 1 }}>
                                    <Typography variant='span' sx={{ fontWeight: 'bold' }}>{tramData.to ? tramData.to.name : ''}</Typography><br />
                                    <Typography variant='span' sx={{ fontSize: '12px', fontWeight: '600' }}>Destination reached!</Typography>
                                </Box>
                            }
                            <Typography variant='span' sx={{ fontSize: '12px' }}></Typography>
                        </Box>
                    </Grid>
                </Grid>
            </TimelineContent>
        </TimelineItem>
    )
}

export default memo(TramWithMap)