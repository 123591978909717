import { Button, Dialog, DialogActions } from '@mui/material';
import useId from '@mui/material/utils/useId';
import { LocalizationProvider, MultiSectionDigitalClock } from '@mui/x-date-pickers';
import React from 'react';
import { useState, useCallback } from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function CustomActionBar(props) {
    const { className, actionBarEvent, isForVia, clickOnCloseAction } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = anchorEl;
    const id = useId();

    return (
        <DialogActions className={className}>
            {
                !isForVia ?
                    <Button
                        id={`picker-actions-${id}`}
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                            actionBarEvent();
                            clickOnCloseAction();
                        }}
                    >
                        Now
                    </Button>
                    :
                    <Button
                        id={`picker-actions-${id}`}
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                            clickOnCloseAction();
                        }}
                    >
                        Ok
                    </Button>
            }
        </DialogActions>
    );
}

const PickerPopUp = (props) => {
    const { open, handleClose, value, changeValue, isVia = false } = props;

    const [defaultValue, setDefaultValue] = useState(value);

    const handleActionBarEvent = useCallback(() => {
        changeValue(new Date());
        setDefaultValue(dayjs(new Date()));
    }, [defaultValue]);

    const views = isVia ? ['minutes'] : ['hours', 'minutes'];

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MultiSectionDigitalClock
                    ampm={false}
                    defaultValue={value}
                    value={defaultValue ?? value}
                    onChange={(val) => {
                        changeValue(new Date(val));
                        setDefaultValue(dayjs(new Date(val)));
                    }}
                    views={views}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                />
                <CustomActionBar actionBarEvent={handleActionBarEvent} isForVia={isVia} clickOnCloseAction={handleClose} />
            </LocalizationProvider>
        </Dialog>
    )
}

export default PickerPopUp;