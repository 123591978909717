import axios from 'axios';
import CONFIG from '../config/config.json'

export const getPlaceCoordinates = (placeId) => {
  const apiKey = CONFIG.GOOGLE_MAPS_API_KEY;

  const url = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${apiKey}`;

  return axios.get(url)
    .then(response => {
      const { results } = response.data;

      if (results.length > 0) {
        const { lat, lng } = results[0].geometry.location;
        return { lat: lat, lng: lng };
      } else {
        throw new Error('Place not found.');
      }
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};