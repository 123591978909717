import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocation } from "@fortawesome/free-solid-svg-icons";
import CONFIG from "../../config/config.json";
import { getPlaceCoordinates } from "../../services/GetPlaces";

const GOOGLE_MAPS_API_KEY = CONFIG.GOOGLE_MAPS_API_KEY;

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("id", id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };

export default function SearchPlaces(props) {
    const {
        id,
        getValue,
        getValueString,
        getDefaultString = null,
        focusActive,
        focusId = 0,
    } = props;
    const [value, setValue] = React.useState(getDefaultString);
    const [inputValue, setInputValue] = React.useState("");
    const [options, setOptions] = React.useState([]);
    const loaded = React.useRef(false);

    const fetchSelectedValueCordinates = async (refId) => {
        try {
            getPlaceCoordinates(refId)
                .then((coordinates) => {
                    // Handle the coordinates response
                    getValue(coordinates);
                })
                .catch((error) => {
                    // Handle errors
                    console.error(error);
                });
        } catch (error) {
            console.log("placeData error", error);
        }
    };

    if (typeof window !== "undefined" && !loaded.current) {
        if (!document.querySelector("#google-maps")) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
                document.querySelector("head"),
                "google-maps"
            );
        }

        loaded.current = true;
    }

    const fetchPlace = React.useMemo(
        () =>
            debounce((request, callback) => {
                let _req = {
                    ...request,
                    componentRestrictions: {
                        country: ["BE", "LU", "NL"],
                    },
                };

                autocompleteService.current.getPlacePredictions(_req, callback);
            }, 400),
        []
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current =
                new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === "") {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetchPlace({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        if (value !== null) {
            if (value.place_id) {
                fetchSelectedValueCordinates(value.place_id);
            }
        } else {
            getValue({
                lat: "",
                lng: "",
            });
        }

        return () => {
            active = false;
        };
    }, [value, inputValue, fetchPlace]);

    React.useEffect(() => {
        setValue(getDefaultString);
        setInputValue(getDefaultString);
    }, [getDefaultString]);

    return (
        <Autocomplete
            freeSolo
            id="google-map-demo"
            key={id}
            sx={{ width: "100%" }}
            getOptionLabel={(option) =>
                typeof option === "string" ? option : option.description
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                // setValue(newValue);
                getValueString(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            onFocus={(event, newValue) => {
                focusActive(focusId);
            }}
            onBlur={(event) => {
                focusActive(0);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Address, stop, station, etc."
                    fullWidth
                    variant="standard"
                />
            )}
            renderOption={(props, option) => {
                const matches =
                    option.structured_formatting.main_text_matched_substrings ||
                    [];

                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [
                        match.offset,
                        match.offset + match.length,
                    ])
                );

                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ display: "flex", width: 44 }}>
                                <FontAwesomeIcon icon={faLocation} />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    width: "calc(100% - 44px)",
                                    wordWrap: "break-word",
                                }}
                            >
                                {parts.map((part, index) => (
                                    <Box
                                        key={index}
                                        component="span"
                                        sx={{
                                            fontWeight: part.highlight
                                                ? "bold"
                                                : "regular",
                                        }}
                                    >
                                        {part.text}
                                    </Box>
                                ))}

                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    {
                                        option.structured_formatting
                                            .secondary_text
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}
