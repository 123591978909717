import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from "@mui/lab";
import {
    Box,
    Divider,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { memo, useCallback, useState } from "react";
import {
    faMinus,
    faPlus,
    faWalking,
    faCar,
} from "@fortawesome/free-solid-svg-icons";
import Map, { Layer, Marker, NavigationControl, Source } from "react-map-gl";
import moment from "moment";
import "../customTimeline.css";
import { secondsToMinutes } from "../../../common/common";
import CONFIG from "../../../config/config.json";
const polyline = require("@mapbox/polyline");

const PointsBox = (props) => {
    const { points = "" } = props;
    return (
        <Box
            sx={{
                height: "10px",
                width: "10px",
                borderRadius: "50%",
                bgcolor: "primary.main",
            }}
            className="d-flex justify-content-center align-items-center"
        >
            <Typography
                variant="span"
                sx={{ fontWeight: "bold", fontSize: "12px", color: "#fff" }}
            >
                {points}
            </Typography>
        </Box>
    );
};

const dottedLineStyle = {
    background: "none",
    borderRight: `5px dotted ${CONFIG.PRIMARY_COLOR}`,
    flex: 1,
    height: "100%",
    margin: 0,
    padding: 0,
};

const CarWithMap = (props) => {
    const {
        isLastStep = false,
        isFristStep = false,
        data = {},
        oddOrEven,
    } = props;

    const [isViewMap, setIsViewMap] = useState(false);

    const handleChange = useCallback(
        () => setIsViewMap(!isViewMap),
        [isViewMap]
    );

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
        noSsr: true,
    });

    let coordinates = polyline.decode(data.legGeometry.points);

    coordinates.map((cordArray) => cordArray.reverse());

    const geojson = {
        type: "FeatureCollection",
        features: [
            {
                type: "Feature",
                geometry: {
                    type: "LineString",
                    coordinates: coordinates,
                },
            },
        ],
    };

    const layerStyle = {
        id: "my-layer",
        type: "line",
        paint: {
            "line-color": CONFIG.PRIMARY_COLOR,
            "line-width": 3,
            "line-dasharray": [1, 2],
        },
        layout: {
            "line-join": "round",
            "line-cap": "round",
        },
    };

    return (
        <TimelineItem
            sx={{
                background:
                    oddOrEven % 2 === 1
                        ? ""
                        : "linear-gradient(90deg,#f8f8f8 90px,#f8f8f8 0)",
                borderRadius: "10px",
            }}
        >
            <TimelineOppositeContent
                className={
                    isFristStep
                        ? "step-frist"
                        : isLastStep
                        ? "step-last"
                        : "common-step"
                }
                sx={{ paddingInline: "2px", overflow: "hidden" }}
                color="textSecondary"
            >
                <Grid
                    container
                    className={
                        isFristStep ? "step-frist-walk-padding" : "walk-padding"
                    }
                    sx={{ height: "100%", justifyContent: "space-between" }}
                >
                    <Grid
                        className={isLastStep ? "padding-top" : ""}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        xl={12}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "start",
                        }}
                    >
                        <Typography
                            className="step-font"
                            variant="span"
                            sx={{ fontWeight: "bold" }}
                        >
                            {moment(data.startTime).format("HH:mm")}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        xl={12}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            flexDirection: "column",
                            paddingBlock: isLastStep ? 0 : "16px",
                        }}
                    >
                        <Box
                            className={
                                "d-flex justify-content-center align-items-center flex-column"
                            }
                        >
                            <FontAwesomeIcon fontSize={"30px"} icon={faCar} />
                        </Box>
                    </Grid>
                    {isViewMap && (
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            xl={12}
                            sx={{ height: "240px", transition: "all 1s" }}
                        />
                    )}
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        xl={12}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: isLastStep ? "start" : "center",
                            flexDirection: "column",
                            paddingBlock: isLastStep ? 0 : 1,
                        }}
                    >
                        <Typography
                            className="step-font"
                            variant="span"
                            sx={{ fontWeight: "bold" }}
                        >
                            {moment(data.endTime).format("HH:mm")}
                        </Typography>
                    </Grid>
                </Grid>
            </TimelineOppositeContent>
            <TimelineSeparator>
                <Box sx={{ height: "20px" }}>
                    {!isFristStep && (
                        <TimelineConnector style={dottedLineStyle} />
                    )}
                </Box>
                {isLastStep ? (
                    <>
                        <Box>
                            <TimelineDot sx={{ margin: 0 }} color="primary" />
                        </Box>
                        <TimelineConnector style={dottedLineStyle} />
                        <TimelineDot
                            sx={{ margin: 0, marginBottom: 6 }}
                            color="primary"
                        >
                            <PointsBox points={"B"} />
                        </TimelineDot>
                    </>
                ) : isFristStep ? (
                    <>
                        <TimelineDot sx={{ margin: 0 }} color="primary">
                            <PointsBox points={"A"} />
                        </TimelineDot>
                        <TimelineConnector style={dottedLineStyle} />
                    </>
                ) : (
                    <>
                        <TimelineDot sx={{ margin: 0 }} color="primary" />
                        <TimelineConnector style={dottedLineStyle} />
                    </>
                )}
            </TimelineSeparator>
            <TimelineContent sx={{ padding: 0, width: "100%" }}>
                <Grid
                    container
                    sx={{
                        height: "100%",
                        justifyContent: "space-between",
                        padding: 1,
                        paddingTop: 2,
                        width: "100%",
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        xl={12}
                        sx={{ paddingBottom: 1, paddingInline: 2 }}
                    >
                        <Typography
                            variant="span"
                            sx={{ fontSize: "20px", fontFamily: "Celebes" }}
                        >
                            {data["from"] ? data["from"].name : ""}
                        </Typography>
                        {/* <Typography sx={{ fontSize: '12px' }}>Vertrek</Typography> */}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        xl={12}
                        sx={{ padding: "16px 0px 16px 16px" }}
                    >
                        <Box className="d-flex">
                            <Box sx={{ flex: 2 }}>
                                <Typography
                                    variant="span"
                                    sx={{
                                        fontWeight: "bold",
                                        marginRight: 2,
                                        fontSize: "14px",
                                        fontFamily: "Celebes-semi-bold",
                                    }}
                                >
                                    To Drive
                                </Typography>
                                <Typography
                                    variant="span"
                                    sx={{ fontSize: "14px", opacity: 0.6 }}
                                >
                                    {secondsToMinutes(data.duration)}
                                </Typography>
                            </Box>
                            <Divider />
                            <Box
                                className="d-flex justify-content-between"
                                onClick={handleChange}
                                sx={{ flex: 1, cursor: "pointer" }}
                            >
                                {!isMobile ? (
                                    <Typography
                                        variant="span"
                                        sx={{ fontSize: "14px" }}
                                    >
                                        View driving route
                                    </Typography>
                                ) : (
                                    <Box></Box>
                                )}
                                <Box
                                    className="d-flex justify-content-center align-items-center"
                                    sx={{
                                        height: "20px",
                                        width: "20px",
                                        borderRadius: "50%",
                                        boxShadow: 3,
                                        bgcolor: "#fff",
                                    }}
                                >
                                    <FontAwesomeIcon
                                        style={{
                                            fontWeight: "400",
                                            fontSize: "14px",
                                            color: CONFIG.PRIMARY_COLOR,
                                        }}
                                        icon={isViewMap ? faMinus : faPlus}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        {isViewMap && (
                            <Box
                                sx={{
                                    height: "250px",
                                    width: "100%",
                                    marginTop: 2,
                                    overflow: "hidden",
                                }}
                            >
                                <Map
                                    initialViewState={{
                                        latitude: coordinates[0][1],
                                        longitude: coordinates[0][0],
                                        zoom: 14,
                                    }}
                                    containerStyle={{
                                        width: "100%",
                                        height: "100%",
                                    }}
                                    mapStyle="mapbox://styles/mapbox/streets-v9"
                                >
                                    <Source
                                        id="my-data"
                                        type="geojson"
                                        data={geojson}
                                    >
                                        <Layer {...layerStyle} />
                                    </Source>
                                    <NavigationControl />
                                    <Marker
                                        longitude={coordinates[0][0]}
                                        latitude={coordinates[0][1]}
                                        color={CONFIG.PRIMARY_COLOR}
                                    />
                                    <Marker
                                        longitude={
                                            coordinates[
                                                coordinates.length - 1
                                            ][0]
                                        }
                                        latitude={
                                            coordinates[
                                                coordinates.length - 1
                                            ][1]
                                        }
                                        color={"green"}
                                    />
                                </Map>
                            </Box>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        xl={12}
                        sx={{ paddingBlock: 2 }}
                    >
                        {!isLastStep ? (
                            <Box
                                className="d-flex justify-content-start align-items-center"
                                sx={{ paddingInline: 2 }}
                            >
                                <Typography
                                    variant="span"
                                    sx={{ fontSize: "16px" }}
                                >
                                    {data.to ? data.to.name : ""}
                                </Typography>
                            </Box>
                        ) : (
                            <Box sx={{ paddingInline: 2 }}>
                                <Typography
                                    variant="span"
                                    sx={{ fontWeight: "bold" }}
                                >
                                    {data.to ? data.to.name : ""}
                                </Typography>
                                <br />
                                <Typography
                                    variant="span"
                                    sx={{ fontSize: "12px", fontWeight: "600" }}
                                >
                                    Destination reached!
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </TimelineContent>
        </TimelineItem>
    );
};

export default memo(CarWithMap);
