import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from "@mui/lab";
import { Box, Grid, Link, List, ListItem, Typography } from "@mui/material";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { secondsToMinutes } from "../../../common/common";
import CONFIG from "../../../config/config.json";
import "../customTimeline.css";

const PointsBox = (props) => {
    const { points = "" } = props;
    return (
        <Box
            sx={{
                height: "25px",
                width: "25px",
                borderRadius: "50%",
                border: "2px solid #fff",
                bgcolor: "primary.main",
            }}
            className="d-flex justify-content-center align-items-center"
        >
            <Typography
                variant="span"
                sx={{ fontWeight: "bold", fontSize: "12px", color: "#fff" }}
            >
                {points}
            </Typography>
        </Box>
    );
};

export default function BusWithMap(props) {
    const {
        style = {},
        isFristStep = false,
        isLastStep = false,
        busData = {},
        oddOrEven,
    } = props;

    const [isIntermediateStops, setIsIntermediateStops] = useState(false);

    const handleChange = useCallback(
        () => setIsIntermediateStops(!isIntermediateStops),
        [isIntermediateStops]
    );

    return (
        <TimelineItem
            sx={{
                background:
                    oddOrEven % 2 === 1
                        ? ""
                        : "linear-gradient(90deg,#f8f8f8 90px,#f8f8f8 0)",
                ...style,
                borderRadius: "10px",
            }}
        >
            <TimelineOppositeContent
                className={
                    isFristStep
                        ? "step-frist"
                        : isLastStep
                        ? "step-last"
                        : "common-step"
                }
                sx={{ paddingInline: "2px", overflow: "hidden" }}
                color="textSecondary"
            >
                <Grid
                    container
                    sx={{
                        height: "100%",
                        justifyContent: "space-between",
                        paddingBlock: 1,
                    }}
                >
                    <Grid
                        className={isLastStep ? "padding-top" : ""}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        xl={12}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "start",
                        }}
                    >
                        <Typography
                            className="step-font"
                            variant="span"
                            sx={{ fontWeight: "bold" }}
                        >
                            {moment(busData.startTime).format("HH:mm")}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        xl={12}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            paddingBlock: "5px",
                        }}
                    >
                        <Box
                            className={
                                "d-flex justify-content-center align-items-center flex-column"
                            }
                            sx={{ objectFit: "cover", height: 30, width: 30 }}
                        >
                            {/* <FontAwesomeIcon className='step-icon' icon={faBusAlt} /> */}
                            <img
                                alt="subway"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "contain",
                                }}
                                src={
                                    require("../../../assets/icons/solid/busSolid.svg")
                                        .default
                                }
                            />
                            {/* <Typography sx={{ fontSize: '14px' }}>28</Typography> */}
                        </Box>
                    </Grid>
                    <Grid
                        className="routes-justify"
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        xl={12}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "end",
                            paddingBottom: 1,
                        }}
                    >
                        <Typography
                            className="step-font"
                            variant="span"
                            sx={{ fontWeight: "bold" }}
                        >
                            {moment(busData.endTime).format("HH:mm")}
                        </Typography>
                    </Grid>
                </Grid>
            </TimelineOppositeContent>
            <TimelineSeparator>
                <Box
                    sx={{
                        height: "20px",
                        width: "2px",
                        bgcolor: CONFIG.PRIMARY_COLOR,
                    }}
                >
                    {!isFristStep && <TimelineConnector />}
                </Box>
                {isLastStep ? (
                    <>
                        <Box>
                            <TimelineDot sx={{ margin: 0 }} color="primary" />
                        </Box>
                        <TimelineConnector sx={{ bgcolor: "primary.main" }} />
                        <TimelineDot
                            sx={{ margin: 0, marginBottom: 6 }}
                            color="primary"
                        >
                            <PointsBox points={"B"} />
                        </TimelineDot>
                    </>
                ) : isFristStep ? (
                    <>
                        <TimelineDot sx={{ margin: 0 }} color="primary">
                            <PointsBox points={"A"} />
                        </TimelineDot>
                        <TimelineConnector sx={{ bgcolor: "primary.main" }} />
                    </>
                ) : (
                    <>
                        <TimelineDot sx={{ margin: 0 }} color="primary" />
                        <TimelineConnector sx={{ bgcolor: "primary.main" }} />
                    </>
                )}
            </TimelineSeparator>
            <TimelineContent sx={{ padding: 0, width: "100%" }}>
                <Grid
                    container
                    sx={{
                        height: "100%",
                        justifyContent: "space-between",
                        padding: 1,
                        width: "100%",
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        xl={12}
                        sx={{ paddingBlock: 1, paddingInline: 2 }}
                    >
                        <Typography
                            variant="span"
                            sx={{
                                fontWeight: "bold",
                                fontFamily: "Celebes",
                                fontSize: "18px",
                            }}
                        >
                            {busData.from.name}{" "}
                            <Typography
                                variant="span"
                                sx={{
                                    fontSize: "12px",
                                    opacity: 0.6,
                                    marginLeft: 2,
                                }}
                            >
                                {secondsToMinutes(busData.duration)}
                            </Typography>
                        </Typography>
                        {/* <Typography sx={{ fontSize: '12px' }}>Vertrek</Typography> */}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        xl={12}
                        sx={{ paddingBlock: 2, paddingInline: 2 }}
                    >
                        <Box className="d-flex">
                            <Box sx={{ flex: 3 }}>
                                <Typography
                                    variant="span"
                                    sx={{ fontSize: "14px", color: "#747473" }}
                                >
                                    {busData.route}
                                </Typography>
                            </Box>
                            {busData.agencyName && (
                                <Box sx={{ flex: 1, textAlign: "center" }}>
                                    <Link
                                        target="_blank"
                                        href={busData.agencyUrl}
                                        underline="none"
                                    >
                                        {busData.agencyName}
                                    </Link>
                                </Box>
                            )}
                            {busData.intermediateStops &&
                                busData.intermediateStops.length > 0 && (
                                    <Box
                                        onClick={handleChange}
                                        className="d-flex justify-content-end"
                                        sx={{ flex: 1 }}
                                    >
                                        <Box
                                            className="d-flex justify-content-center align-items-center"
                                            sx={{
                                                height: "20px",
                                                width: "20px",
                                                borderRadius: "50%",
                                                boxShadow: 3,
                                                bgcolor: "#fff",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                style={{
                                                    fontWeight: "400",
                                                    fontSize: "12px",
                                                    color: CONFIG.PRIMARY_COLOR,
                                                }}
                                                icon={
                                                    !isIntermediateStops
                                                        ? faPlus
                                                        : faMinus
                                                }
                                            />
                                        </Box>
                                    </Box>
                                )}
                        </Box>
                        {isIntermediateStops && (
                            <Box sx={{ width: "100%", marginTop: 2 }}>
                                <List
                                    sx={{
                                        listStyleType: "disc",
                                        listStylePosition: "inside",
                                        left: -45,
                                    }}
                                >
                                    {busData.intermediateStops.map((steps) => {
                                        return (
                                            <ListItem
                                                sx={{
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                                key={steps.name}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            width: "15px",
                                                            height: "2px",
                                                            bgcolor:
                                                                "primary.main",
                                                        }}
                                                    />
                                                    {/* <FontAwesomeIcon style={{ marginRight: '10px' }} icon={faDotCircle} color={CONFIG.PRIMARY_COLOR} /> */}
                                                    <Typography
                                                        variant="span"
                                                        sx={{
                                                            fontSize: "12px",
                                                            opacity: "0.6",
                                                            marginLeft: 2,
                                                        }}
                                                    >
                                                        {steps.name}
                                                    </Typography>
                                                </Box>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Box>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        xl={12}
                        sx={{ paddingBlock: 2 }}
                    >
                        <Box
                            className="d-flex justify-content-between align-items-center"
                            sx={{ paddingInline: 2 }}
                        >
                            {!isLastStep ? (
                                <Typography
                                    variant="span"
                                    sx={{ fontSize: "16px" }}
                                >
                                    {busData.to ? busData.to.name : ""}
                                </Typography>
                            ) : (
                                <Box sx={{ paddingInline: 1 }}>
                                    <Typography
                                        variant="span"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        {busData.to ? busData.to.name : ""}
                                    </Typography>
                                    <br />
                                    <Typography
                                        variant="span"
                                        sx={{
                                            fontSize: "12px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        Destination reached!
                                    </Typography>
                                </Box>
                            )}
                            <Typography
                                variant="span"
                                sx={{ fontSize: "15px" }}
                            >
                                Perron A3
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </TimelineContent>
        </TimelineItem>
    );
}
