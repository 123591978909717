import { Box, Container, Grid, IconButton, Link, Typography } from '@mui/material';
import React from 'react';
import CONFIG from '../../config/config.json'

const Footer = () => {
    return (
        <Box className="col-md-11">
            <Container>
                <Grid container gap={2} sx={{ justifyContent: 'space-between' }}>
                    <Grid item xs={12} sm={12} md={4} xd={4} className="footer-border">
                        <Box className={"d-flex align-items-center"}>
                            <img alt='logo' src={require('../../assets/images/Automicle-symbol.png')} />
                            <Typography sx={{ marginLeft: 2, fontWeight: 'bold' }} variant='h4'>automicle</Typography>
                        </Box>
                        <Box className="mt-4">
                            <Typography variant='span' sx={{ fontWeight: 100 }}>Our friendly docs, APIs & SDKs streamline <br /> adding local mobility options to your brand <br /> Boost value & convenience for customers</Typography>
                        </Box>
                        <Box className="mt-4">
                            <IconButton target="_blank" href='https://www.linkedin.com/company/automicle/'>
                                <img alt='linkdln' src={require('../../assets/icons/linkedln.svg').default} />
                            </IconButton>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} xd={6}>
                        <Grid container gap={3}>
                            <Grid item xs={12} sm={12} md={5} xd={5}>
                                <Typography variant='h4' sx={{ fontWeight: 'bold', color: CONFIG.PRIMARY_COLOR, marginBottom: 2 }}>
                                    <Link target="_blank" href="#" underline="none">
                                        {"About"}
                                    </Link>
                                </Typography>
                                <Typography className='mt-2' variant='h6' sx={{ fontSize: '16px' }}>
                                    <Link target="_blank" href="https://www.automicle.com/about" underline="none" sx={{ color: '#000' }}>
                                        {"About us"}
                                    </Link>
                                </Typography>
                                <Typography className='mt-2' variant='h6' sx={{ fontSize: '16px' }}>
                                    <Link target="_blank" href="https://calendly.com/automicle" underline="none" sx={{ color: '#000' }}>
                                        {"Pricing"}
                                    </Link>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} xd={4}>
                                <Typography variant='h4' sx={{ fontWeight: 'bold', color: CONFIG.PRIMARY_COLOR, marginBottom: 2 }}>
                                    <Link href="#" underline="none">
                                        {"Contact us"}
                                    </Link>
                                </Typography>
                                <Typography className='mt-2' variant='h6' sx={{ fontSize: '16px' }}>
                                    <Link target="_blank" href="mailTo:info@automicle.com" underline="none" sx={{ color: '#000' }}>
                                        <Box className="d-flex align-items-center">
                                            <img alt='mail' src={require('../../assets/icons/mail.svg').default} />
                                            <Typography sx={{ marginLeft: 2 }} variant='span'>{"info@automicle.com"}</Typography>
                                        </Box>
                                    </Link>
                                </Typography>
                                <Typography className='mt-2' variant='h6' sx={{ fontSize: '16px' }}>
                                    <Link target="_blank" href="tel:+31 850602 886" underline="none" sx={{ color: '#000' }}>
                                        <Box className="d-flex align-items-center">
                                            <img alt="call" src={require('../../assets/icons/call.svg').default} />
                                            <Typography sx={{ marginLeft: 2 }} variant='span'>{"+31 850602 886"}</Typography>
                                        </Box>
                                    </Link>
                                </Typography>
                                <Typography className='mt-2' variant='h6' sx={{ fontSize: '16px' }}>
                                    <Link target="_blank" href="https://calendly.com/automicle" underline="none" sx={{ color: '#000' }}>
                                        <Box className="d-flex align-items-center">
                                            <img alt='calender-add' src={require('../../assets/icons/calendar-add.svg').default} />
                                            <Typography sx={{ marginLeft: 2 }} variant='span'>{"Contact us"}</Typography>
                                        </Box>
                                    </Link>
                                </Typography>
                                <Typography className='mt-2' variant='h6' sx={{ fontSize: '16px' }}>
                                    <Link target="_blank" href="#" underline="none" sx={{ color: '#000' }}>
                                        <Box className="d-flex align-items-center">
                                            <img alt="map-pin" src={require('../../assets/icons/map-pin.svg').default} />
                                            <Typography sx={{ marginLeft: 2 }} variant='span'>{"Graaf wichmaniaan 58, 1405 HC Bussum The Netherlands"}</Typography>
                                        </Box>
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Footer