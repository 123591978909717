import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import '../headerForm.css';
import dayjs from 'dayjs';
import { DesktopTimePicker } from '@mui/x-date-pickers';
import PickerPopUp from './PikcerPopUp';
import { useCallback } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';

const CustomTimePicker = (props) => {
  const { label, currentValue, onChangeValue } = props;
  const [dynamicOpen, setDynamicOpen] = React.useState(false);
  const [defaultValue, setDefaultValue] = React.useState(dayjs(currentValue));

  const handleChangeValue = useCallback((time) => {
    setDefaultValue(dayjs(time.toISOString()));
    onChangeValue(dayjs(time));
  }, [defaultValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopTimePicker
        open={false}
        onClose={() => setDynamicOpen(false)}
        ampm={false}
        defaultValue={`${defaultValue}`}
        sx={{ borderRadius: '20px', flex: 0.6 }}
        className='date-picker date-Picker-font'
        label={label}
        onChange={(value) => { onChangeValue(value) }}
        slotProps={{
          textField: {
            onClick: () => setDynamicOpen(true),
            value: defaultValue
          }
        }}
        slots={{
          textField: textFieldProps => <TextField {...textFieldProps} InputProps={{
              endAdornment: (
                  <InputAdornment position="end">
                      <IconButton edge="end">
                          <FontAwesomeIcon icon={faClock} style={{ fontSize: '22px' }} />
                      </IconButton>
                  </InputAdornment>
              ),
              readOnly: true
          }} />
      }} 
      />
      <PickerPopUp open={dynamicOpen} handleClose={() => { setDynamicOpen(false) }} value={defaultValue} changeValue={handleChangeValue} />
    </LocalizationProvider>
  );
}

export default CustomTimePicker