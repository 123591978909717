import React, { useCallback, useState } from "react";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CONFIG from "../../config/config.json";

function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
        <SliderThumb {...other}>
            {children}
            <span className="airbnb-bar" />
            <span className="airbnb-bar" />
            <span className="airbnb-bar" />
        </SliderThumb>
    );
}

const CustomSilder = (props) => {
    const { speedValue } = props;
    const [value, setValue] = useState(50);
    const handleChange = useCallback(
        (event, newValue) => {
            speedValue(newValue);
            setValue(newValue);
        },
        [value]
    );

    const AirbnbSlider = styled(Slider)(({ theme }) => ({
        color: CONFIG.PRIMARY_COLOR,
        height: 3,
        padding: "13px 0",
        "& .MuiSlider-thumb": {
            height: 27,
            width: 27,
            backgroundColor: "#fff",
            border: "1px solid currentColor",
            "&:hover": {
                boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
            },
            "& .airbnb-bar": {
                height: 9,
                width: 1,
                backgroundColor: "currentColor",
                marginLeft: 1,
                marginRight: 1,
            },
        },
        "& .MuiSlider-track": {
            height: 3,
        },
        "& .MuiSlider-rail": {
            color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
            opacity: theme.palette.mode === "dark" ? undefined : 1,
            height: 3,
        },
    }));

    function valuetext(value) {
        return `${value === 0 ? "Slowly" : value === 50 ? "Average" : "Fast"}`;
    }

    return (
        <>
            <AirbnbSlider
                value={value}
                onChange={handleChange}
                slots={{ thumb: AirbnbThumbComponent }}
                getAriaLabel={(index) =>
                    index === 0 ? "Slowly" : index === 50 ? "Average" : "Fast"
                }
                defaultValue={0}
                step={50}
                valueLabelDisplay="off"
                getAriaValueText={valuetext}
            />
            <Box
                sx={{
                    width: `100%`,
                    display: "flex",
                    justifyContent: `${
                        value === 0 ? "start" : value === 50 ? "center" : "end"
                    }`,
                }}
            >
                <Typography
                    variant="span"
                    sx={{ fontSize: "12px", fontWeight: "600" }}
                >
                    {value === 0 ? "Slowly" : value === 50 ? "Average" : "Fast"}
                </Typography>
            </Box>
        </>
    );
};

export default React.memo(CustomSilder);
