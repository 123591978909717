import axios from "axios";
import CONFIG from "../config/config.json";

const GetRequest = async (data = {}) => {
    const API_URL = CONFIG.TOKEN_GENERATE_API_URL;
    const header = {
        "client-id": CONFIG.TOKEN_GENERATE_CLEINT_ID,
        "client-secret": CONFIG.TOKEN_GENERATE_CLEINT_SECRET,
        "X-Automicle-Environment": "sandbox",
    };
    return new Promise((resolve, reject) => {
        axios
            .post("https://api.dev.automicle.com/v1/authpay/get-token", {
                customerName: "Student Mobility",
                widgetID: "f92b146b-e14a-4d83-8c2f-a1e2e4bdd4de",
            })
            .then(function (response) {
                try {
                    resolve(response.data);
                } catch (error) {
                    console.log("throw error", error);
                    throw new Error(error.message);
                }
            })
            .catch(function (error) {
                console.log("Generate Token Error", error);
                reject(error.message);
            });
    });
}; /* 
const GetRequest = async (data = {}) => {
    const API_URL = CONFIG.TOKEN_GENERATE_API_URL;
    const header = {
        "client-id": CONFIG.TOKEN_GENERATE_CLEINT_ID,
        "client-secret": CONFIG.TOKEN_GENERATE_CLEINT_SECRET,
        "X-Automicle-Environment": "sandbox"
    }
    return new Promise((resolve, reject) => {
        axios.get(`${API_URL}`, {
            headers: header
        })
            .then(function (response) {
                try {
                    resolve(response.data);

                } catch (error) {
                    console.log('throw error', error)
                    throw new Error(error.message);
                }
            })
            .catch(function (error) {
                console.log('Generate Token Error', error)
                reject(error.message);
            });

    })

}
 */
export default GetRequest;
