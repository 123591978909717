import React, { createContext, useState, useMemo } from "react";
export const AppContext = createContext(null);
export const AppContextProvider = ({ children }) => {
    const [globalData, setGlobalData] = useState({});
    const [openModal, setOpenModal] = useState(false);

    const value = useMemo(
        () => ({
            globalData,
            setGlobalData,
            openModal,
            setOpenModal,
        }),
        [globalData, openModal]
    );

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
