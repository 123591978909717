import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CONFIG from "../../config/config.json";
import LabelBox from "../common/labelBox";
import LabelIconBox from "../common/labelIconBox";
import "./routesCard.css";
import moment from "moment";
import {
    round2digit,
    secondsToHms,
    secondsToMinutes,
} from "../../common/common";

const RoutesCard = (props) => {
    const { handleOnChange, value, currentActiveCard = 1 } = props;
    const isActive = currentActiveCard === value.uniqueId;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
        noSsr: true,
    });
    const iconUrlDictionary = {
        WALK: require(`../../assets/icons/walk.svg`).default,
        SUBWAY: require(`../../assets/icons/metroPrimary.svg`).default,
        RAIL: require(`../../assets/icons/TrainSimple.svg`).default,
        BUS: require(`../../assets/icons/bus.svg`).default,
        CAR: require(`../../assets/icons/car_icon.svg`).default,
        BICYCLE: require(`../../assets/icons/bicycle_icon.svg`).default,
        TRAM: require(`../../assets/icons/TramPrimary.svg`).default,
    };
    const legsLength = value.legs.length;
    const lastIndexOfArray = legsLength > 0 ? legsLength - 1 : 0;
    return (
        <Card
            className={`routes-card ${isActive && !isMobile ? "active" : ""}`}
            sx={{
                borderLeft: `${
                    isActive && !isMobile
                        ? `4px solid ${CONFIG.PRIMARY_COLOR}`
                        : ""
                }`,
                boxShadow: 4,
                borderRadius: 3,
            }}
            key={Math.random()}
            onClick={() => {
                handleOnChange(value.id);
            }}
        >
            <Box className="d-flex align-items-center justify-content-between routes-card-header">
                <Typography
                    variant="span"
                    className={"card-font"}
                    sx={{
                        fontWeight: "bold",
                        marginRight: "12px",
                        color: "primary.main",
                        fontSize: "16px",
                    }}
                >
                    {moment(value.startTime).format("HH:mm")}{" "}
                    <span style={{ opacity: "0.5" }}> - </span>{" "}
                    {moment(value.endTime).format("HH:mm")}
                </Typography>
                <LabelIconBox
                    className={"card-font"}
                    icon={
                        <FontAwesomeIcon
                            style={{
                                marginRight: "5px",
                                fontSize: "14px",
                                color: "black",
                            }}
                            icon={faClock}
                        />
                    }
                    label={secondsToHms(value.duration)}
                />
                <LabelIconBox
                    className={"card-font"}
                    icon={
                        <img
                            alt="routes icon"
                            style={{ height: 20, width: 20 }}
                            src={
                                require("../../assets/icons/routes-white.svg")
                                    .default
                            }
                        />
                    }
                    label={value.totalStops}
                    isBackgroud={true}
                    bgColor="primary.main"
                />
            </Box>
            <Box className="mt-3 d-flex justify-content-between">
                <Box
                    sx={{ flex: 5, paddingInline: 0, overflowX: "auto" }}
                    className="d-flex"
                >
                    {value.legs.map((leg, i) => {
                        return (
                            <div
                                key={"timing" + leg.startTime}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: 0,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginInline: 0,
                                    }}
                                >
                                    <img
                                        alt="walk"
                                        className="mobile-image"
                                        src={iconUrlDictionary[leg.mode]}
                                    />
                                    <Box
                                        className="d-flex align-items-center"
                                        sx={{
                                            width: "60px",
                                            height: "20px",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <Typography
                                            className={"card-font"}
                                            variant="span"
                                            sx={{
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                width: "65px",
                                                height: "20px",
                                                overflow: "hidden",
                                                textAlign: "center",
                                            }}
                                        >
                                            {secondsToMinutes(leg.duration)}
                                        </Typography>
                                    </Box>
                                </Box>
                                {lastIndexOfArray === i ? (
                                    <></>
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faGreaterThan}
                                        style={{
                                            fontWeight: "normal",
                                            fontSize: "10px",
                                            color: CONFIG.PRIMARY_COLOR,
                                        }}
                                    />
                                )}
                            </div>
                        );
                    })}
                </Box>
                {/*    {!isActive && !isMobile && (
                    <Box sx={{ flex: 1, textAlign: "end" }}>
                        <FontAwesomeIcon
                            icon={faGreaterThan}
                            style={{ fontSize: "14px" }}
                        />
                    </Box>
                )} */}
            </Box>
            <Box className="d-flex mt-3">
                <Box sx={{ marginRight: 2 }}>
                    <LabelBox
                        label={` € ${round2digit(value.minimumFare.amount)}`}
                        color="#999"
                    />
                </Box>
                <Box sx={{ marginRight: 2 }}>
                    <LabelBox
                        color="white"
                        label={`${value.co2} gm CO2`}
                        bgcolor="#a0e2ad"
                    />
                </Box>
                <Box sx={{ marginRight: 2 }}>
                    <LabelBox
                        color="white"
                        label={`${value.calories} cal`}
                        bgcolor={CONFIG.PRIMARY_COLOR}
                    />
                </Box>
            </Box>
        </Card>
    );
};

export default RoutesCard;
