import { Timeline, timelineOppositeContentClasses } from "@mui/lab";
import React, { memo } from "react";
import WalkWithMap from "../walk-with-map/walkWithMap";
import BusWithMap from "./time-line-def-transport/busWithMap";
import TramWithMap from "./time-line-def-transport/tramWithMap";
import BicycleWithMap from "./time-line-def-transport/bicycleWithMap";
import RailWithMap from "./time-line-def-transport/railWithMap";
import CarWithMap from "./time-line-def-transport/carWithMap";
import { generateRandomToken } from "../../common/common";
import TrainWithMap from "./time-line-def-transport/trainWtihMap";

const CustomTimeLine = (props) => {
    const { timeLine = [] } = props;
    return (
        <Timeline
            sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.1,
                    padding: 0,
                },
                borderRadius: 2,
            }}
        >
            {timeLine.map((place, i) => {
                let index = i;
                let key = generateRandomToken(i);

                if (place.mode === "WALK") {
                    return (
                        <WalkWithMap
                            key={key}
                            isFristStep={i == 0 ? true : false}
                            isLastStep={
                                timeLine.length - 1 === i ? true : false
                            }
                            walkData={place}
                            oddOrEven={index}
                        />
                    );
                } else if (place.mode === "TRAM") {
                    return (
                        <TramWithMap
                            key={key}
                            isFristStep={i == 0 ? true : false}
                            isLastStep={
                                timeLine.length - 1 === i ? true : false
                            }
                            style={{}}
                            tramData={place}
                            oddOrEven={index}
                        />
                    );
                } else if (place.mode === "BICYCLE") {
                    return (
                        <BicycleWithMap
                            key={key}
                            isFristStep={i == 0 ? true : false}
                            isLastStep={
                                timeLine.length - 1 === i ? true : false
                            }
                            style={{}}
                            data={place}
                            oddOrEven={index}
                        />
                    );
                } else if (place.mode === "CAR") {
                    return (
                        <CarWithMap
                            key={key}
                            isFristStep={i == 0 ? true : false}
                            isLastStep={
                                timeLine.length - 1 === i ? true : false
                            }
                            style={{}}
                            data={place}
                            oddOrEven={index}
                        />
                    );
                } else if (place.mode === "BUS") {
                    return (
                        <BusWithMap
                            key={key}
                            isFristStep={i == 0 ? true : false}
                            isLastStep={
                                timeLine.length - 1 === i ? true : false
                            }
                            style={{}}
                            busData={place}
                            oddOrEven={index}
                        />
                    );
                } else if (place.mode === "RAIL") {
                    return (
                        <RailWithMap
                            key={key}
                            isFristStep={i == 0 ? true : false}
                            isLastStep={
                                timeLine.length - 1 === i ? true : false
                            }
                            style={{}}
                            railData={place}
                            oddOrEven={index}
                        />
                    );
                } else if (place.mode === "SUBWAY") {
                    return (
                        <TrainWithMap
                            key={key}
                            isFristStep={i == 0 ? true : false}
                            isLastStep={
                                timeLine.length - 1 === i ? true : false
                            }
                            style={{}}
                            subwayData={place}
                            oddOrEven={index}
                        />
                    );
                } else {
                    return <React.Fragment key={key}></React.Fragment>;
                }
            })}
        </Timeline>
    );
};

export default memo(CustomTimeLine);
