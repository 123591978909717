import { Box, Typography } from "@mui/material";
import React from "react";

const LabelBox = (props) => {
    const { label = '', bgcolor="#eee", color="black" } = props;
    return (
        <Box sx={{borderRadius: '10px', padding: 1, bgcolor:`${bgcolor}`, width: 'fit-content'}}>
            <Typography sx={{fontSize: '14px', color: `${color}`, fontWeight: 'bold'}} variant="span">{label}</Typography>
        </Box>
    )
}

export default LabelBox;