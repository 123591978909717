import { useState } from "react";
import style from "./style.module.css";
import { IoChevronUpOutline, IoChevronDownOutline } from "react-icons/io5";
//Assets
import { ReactComponent as TimerIcon } from "../../assets/icons/clock_icon.svg";
//components
import Modal from "../Modal";
export default function TimeSpinner({ value, onChange, customStyles }) {
    const [openModal, setOpenModal] = useState(false);
    const [duration, setDuration] = useState(parseInt(value));
    return (
        <span
            className={style.dateTimePicker__container}
            role="button"
            onClick={() => {
                setOpenModal(true);
            }}
            style={{
                padding: "0.77rem 1rem",
                width: "100%",
                ...customStyles,
            }}
            tabIndex="0"
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    setOpenModal(true);
                }
            }}
        >
            <span className={style.svg_container}>
                <TimerIcon size={16} />
            </span>
            <span>{formatTime(duration)}</span>
            {openModal && (
                <Modal setShowModal={setOpenModal}>
                    <TimePicker
                        duration={duration}
                        setDuration={setDuration}
                        onChange={onChange}
                        setOpenModal={setOpenModal}
                    />
                </Modal>
            )}
        </span>
    );
}
TimeSpinner.defaultProps = {
    value: "15",
    onChange: () => console.log("value changing"),
};
function TimePicker({ duration, setDuration, onChange, setOpenModal }) {
    const changeFn = (bool) => {
        let _tempDuration = parseInt(duration);
        if (bool) {
            if (_tempDuration === 1440) {
                return;
            }
            _tempDuration += 15;
        } else {
            if (_tempDuration === 15) {
                return;
            }
            _tempDuration -= 15;
        }
        setDuration(_tempDuration);
        onChange(_tempDuration);
    };

    return (
        <div className={style.timePicker__container}>
            <div className={style.timePicker__ctrls_1}>
                <p>Via time</p>
            </div>
            <div className={style.timePicker__select_window}>
                <div className={style.timePicker__digit_window}>
                    <button
                        className={style.chevrons}
                        onClick={() => changeFn(true)}
                    >
                        <IoChevronUpOutline />
                    </button>
                    <span className={style.digits}>
                        {" "}
                        {formatTime(duration)}
                    </span>
                    <button
                        className={style.chevrons}
                        onClick={() => changeFn(false)}
                    >
                        <IoChevronDownOutline />
                    </button>
                </div>
            </div>
            <div className={style.timePicker__ctrls_2}>
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpenModal(false);
                    }}
                >
                    Close
                </button>
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpenModal(false);
                    }}
                >
                    Done
                </button>
            </div>
        </div>
    );
}
const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours.toString().padStart(2, "0")} hr : ${remainingMinutes
        .toString()
        .padStart(2, "0")} mins`;
};
