import { faEuroSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Box,
    Button,
    Card,
    Grid,
    Pagination,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import {
    generateRandomToken,
    round2digit,
    secondsToHms,
} from "../../common/common";
import LabelIconBox from "../common/labelIconBox";
import usePagination from "../common/Pagination";
import CustomTimeLine from "../custom-time-line/customTimeline";
import RoutesCard from "../routes-card/routesCard";
import "./tripRoutes.css";
import CONFIG from "../../config/config.json";

const TripRoutes = (props) => {
    const {
        tripData = false,
        selectedDestination = "",
        selectedFrom = "",
    } = props;

    const [currentActiveCard, setCurrentActiveCard] = useState("1");
    let [page, setPage] = useState(1);
    const [cardData, setCardData] = useState([]);
    const [timeLineData, setTimeLineData] = useState([]);
    const [timeLineHeaderData, setTimeHeaderLineData] = useState({});
    const [isViewRoutesCard, setViewRoutesCard] = useState(true);
    const [isViewTimeLine, setViewTimeLine] = useState(true);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
        noSsr: true,
    });

    const PER_PAGE = 3;

    const count = Math.ceil(cardData.length / PER_PAGE);

    const _DATA = usePagination(cardData, PER_PAGE);

    const handleOnChange = (value) => {
        setCurrentActiveCard(value);
        if (isMobile) {
            setViewRoutesCard(false);
            setViewTimeLine(true);
        }
    };

    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
    };

    const addRandomId = (data = []) => {
        data.map((trip) => {
            trip.uniqueId = generateRandomToken();
            if (trip.legs && trip.legs.length > 0) {
                let trainTime = 0;
                let tramTime = 0;
                let BusTime = 0;
                let subwayTime = 0;
                let bicycleTime = 0;
                let carTime = 0;

                let totalStep = 0;
                let isWalkModeRepeated = 0;

                for (const tr of trip.legs) {
                    switch (tr.mode) {
                        case "WALK":
                            // walkTime += tr.duration;
                            if (isWalkModeRepeated == 0) {
                                totalStep += 1;
                                isWalkModeRepeated = 1;
                            }
                            break;

                        case "TRAM":
                            isWalkModeRepeated = 0;
                            totalStep += 1;
                            tramTime += tr.duration;
                            break;

                        case "BUS":
                            isWalkModeRepeated = 0;
                            totalStep += 1;
                            BusTime += tr.duration;
                            break;

                        case "RAIL":
                            isWalkModeRepeated = 0;
                            totalStep += 1;
                            trainTime += tr.duration;
                            break;

                        case "SUBWAY":
                            isWalkModeRepeated = 0;
                            totalStep += 1;
                            subwayTime += tr.duration;
                            break;
                        case "CAR":
                            isWalkModeRepeated = 0;
                            totalStep += 1;
                            carTime += tr.duration;
                            break;
                        case "BICYCLE":
                            isWalkModeRepeated = 0;
                            totalStep += 1;
                            bicycleTime += tr.duration;
                            break;
                        default:
                            break;
                    }
                }
                trip.trainTime = trainTime;
                trip.tramTime = tramTime;
                trip.busTime = BusTime;
                trip.totalStops = totalStep;
                trip.subwayTime = subwayTime;
                trip["carTime"] = carTime;
                trip["bicycleTime"] = bicycleTime;
            }
        });
        return data;
    };

    const backOnCLick = () => {
        setViewRoutesCard(true);
        setViewTimeLine(false);
    };

    useEffect(() => {
        if (!tripData) {
            return;
        } else {
            let newTripData = addRandomId(tripData.itineraries);
            if (newTripData.length > 0) {
                setCurrentActiveCard(newTripData[0].uniqueId);
            }
            setCardData(newTripData);
        }
    }, [tripData]);

    useEffect(() => {
        let index = cardData.findIndex((c) => c.uniqueId === currentActiveCard);
        if (index > -1) {
            setTimeHeaderLineData(cardData[index]);
            setTimeLineData(cardData[index].legs || []);
        }
    }, [currentActiveCard]);

    useEffect(() => {
        if (isMobile) {
            setViewTimeLine(false);
        } else {
            setViewRoutesCard(true);
            setViewTimeLine(true);
        }
    }, [isMobile]);
    return (
        <>
            {!isViewRoutesCard && <Button onClick={backOnCLick}>Back</Button>}
            <Grid container style={{ justifyContent: "space-between" }} gap={1}>
                <Grid
                    className={`main-margin ${
                        isViewRoutesCard ? "is-display-yes" : "is-display-no"
                    }`}
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    xl={4}
                >
                    <Box sx={{ width: "100%" }}>
                        {cardData.map((c) => {
                            return (
                                <RoutesCard
                                    key={c.uniqueId + Math.random()}
                                    handleOnChange={() => {
                                        handleOnChange(c.uniqueId);
                                    }}
                                    value={c}
                                    currentActiveCard={currentActiveCard}
                                />
                            );
                        })}
                    </Box>

                    <Box sx={{ width: "100%", marginTop: 3 }}>
                        <Pagination
                            count={count}
                            size="medium"
                            page={page}
                            color="primary"
                            variant="outlined"
                            shape="rounded"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={7}
                    xl={7}
                    className={`main-container ${
                        isViewTimeLine ? "is-display-yes" : "is-display-no"
                    }`}
                >
                    <Box
                        sx={{
                            width: "100%",
                            boxShadow: 3,
                            borderRadius: "20px",
                            overflow: "hidden",
                        }}
                    >
                        <Card style={{ height: "100%" }}>
                            <Box sx={{ padding: "15px", bgcolor: "#f8f8f8" }}>
                                {!isViewRoutesCard && (
                                    <Box sx={{ marginBottom: 2 }}>
                                        <Box className="d-flex">
                                            <Box sx={{ width: "30%" }}>
                                                <Typography
                                                    variant="span"
                                                    sx={{ fontSize: "14px" }}
                                                >
                                                    By
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: "60%",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                <Typography
                                                    variant="span"
                                                    sx={{
                                                        fontSize: "12px",
                                                        lineHeight:
                                                            "1px !important",
                                                    }}
                                                >
                                                    {selectedFrom}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ width: "10%" }}>
                                                <Typography
                                                    variant="span"
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {moment(
                                                        timeLineHeaderData.startTime
                                                    ).format("HH:mm")}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box className="d-flex">
                                            <Box sx={{ width: "30%" }}>
                                                <Typography
                                                    variant="span"
                                                    sx={{ fontSize: "14px" }}
                                                >
                                                    Destination
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: "60%",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                <Typography
                                                    variant="span"
                                                    sx={{ fontSize: "12px" }}
                                                >
                                                    {selectedDestination}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ width: "10%" }}>
                                                <Typography
                                                    variant="span"
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {moment(
                                                        timeLineHeaderData.endTime
                                                    ).format("HH:mm")}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                )}
                                <Box className="trip-routes-header">
                                    <Grid
                                        container
                                        sx={{ justifyContent: "start" }}
                                    >
                                        <Grid
                                            item
                                            className="trip-grid"
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={4}
                                            xl={4}
                                        >
                                            <LabelIconBox
                                                className="trip-header-font"
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                }}
                                                icon={
                                                    <img
                                                        alt="calender icon"
                                                        style={{
                                                            height: 24,
                                                            width: 24,
                                                        }}
                                                        src={
                                                            require("../../assets/icons/Calender.svg")
                                                                .default
                                                        }
                                                    />
                                                }
                                                label={moment(
                                                    tripData.dateTime
                                                ).format("DD-MM-YYYY")}
                                            />
                                        </Grid>
                                        <Grid
                                            className="trip-grid"
                                            item
                                            xs={4}
                                            sm={4}
                                            md={4}
                                            lg={3}
                                            xl={3}
                                        >
                                            <LabelIconBox
                                                className="trip-header-font"
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                }}
                                                icon={
                                                    <img
                                                        alt="watch icon"
                                                        style={{
                                                            height: 24,
                                                            width: 24,
                                                        }}
                                                        src={
                                                            require("../../assets/icons/Watch.svg")
                                                                .default
                                                        }
                                                    />
                                                }
                                                label={secondsToHms(
                                                    timeLineHeaderData.duration
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                            sm={4}
                                            md={4}
                                            lg={3}
                                            xl={3}
                                        >
                                            <LabelIconBox
                                                className="trip-header-font"
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                }}
                                                icon={
                                                    <img
                                                        alt="routes icon"
                                                        style={{
                                                            height: 24,
                                                            width: 24,
                                                        }}
                                                        src={
                                                            require("../../assets/icons/routes.svg")
                                                                .default
                                                        }
                                                    />
                                                }
                                                label={
                                                    timeLineHeaderData[
                                                        "totalStops"
                                                    ]
                                                        ? timeLineHeaderData[
                                                              "totalStops"
                                                          ]
                                                        : 0
                                                }
                                            />
                                        </Grid>
                                        {/* bottom price section */}
                                        <Grid
                                            item
                                            xs={4}
                                            sm={4}
                                            md={4}
                                            lg={2}
                                            xl={2}
                                        >
                                            <LabelIconBox
                                                className="trip-header-font"
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                }}
                                                icon={
                                                    <img
                                                        alt="euro icon"
                                                        style={{
                                                            height: 24,
                                                            width: 24,
                                                        }}
                                                        src={
                                                            require("../../assets/icons/Euro.svg")
                                                                .default
                                                        }
                                                    />
                                                }
                                                label={
                                                    timeLineHeaderData[
                                                        "minimumFare"
                                                    ]
                                                        ? round2digit(
                                                              timeLineHeaderData[
                                                                  "minimumFare"
                                                              ].amount
                                                          ).toString()
                                                        : "0"
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                            <Card className="card-padding">
                                <CustomTimeLine timeLine={timeLineData} />
                            </Card>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
            <Grid
                container
                style={{
                    justifyContent: "space-around",
                    display: isViewTimeLine ? "" : "none",
                }}
            >
                <Grid item xs={12} sm={12} md={5} xl={5}></Grid>
                <Grid item xs={12} sm={12} md={7} xl={7}>
                    <Card sx={{ marginTop: 2, padding: 2, borderRadius: 2 }}>
                        <Typography
                            sx={{ fontWeight: "bold", textAlign: "center" }}
                            variant="h4"
                        >
                            The price of your trip{" "}
                            <Typography
                                variant="span"
                                sx={{ color: CONFIG.PRIMARY_COLOR }}
                            >
                                {" "}
                                <FontAwesomeIcon
                                    style={{ fontSize: "30px" }}
                                    icon={faEuroSign}
                                />{" "}
                                {timeLineHeaderData["minimumFare"]
                                    ? round2digit(
                                          timeLineHeaderData["minimumFare"]
                                              .amount
                                      ).toString()
                                    : "0"}
                            </Typography>
                        </Typography>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default memo(TripRoutes);
