const generateRandomToken = (i = 0) => {
    let randLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
    let uniqid = randLetter + Date.now();
    return uniqid + i;
}

const msToTime = (ms) => {
    let seconds = (ms / 1000).toFixed(1);
    let minutes = (ms / (1000 * 60)).toFixed(1);
    let hours = (ms / (1000 * 60 * 60)).toFixed(1);
    let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
    if (seconds < 60) return seconds + " Sec";
    else if (minutes < 60) return minutes + " Min";
    else if (hours < 24) return hours + " Hrs";
    else return days + " Days"
}

function secondsToHms(secs) {
    let h = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let m = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let s = Math.ceil(divisor_for_seconds);

    if (!h && !m && !s) {
        return false
    }

    return `${h ? `${h < 10 ? '0' + h : h}:` : "00:"}${m && m !== 0 ? `${m < 10 ? '0' + m : m}:${s < 10 ? '0' + s : s}` : `${s !== 0 ? s : ""}`}`
}

function stringSecondsToHms(d) {
    d = Number(d);
    let h = Math.floor(d / 3600);
    let m = Math.floor(d % 3600 / 60);
    let s = Math.floor(d % 3600 % 60);

    let hDisplay = h > 0 ? h + (h === 1 ? " Hrs, " : m !== '' ? " Hrs, " : " Hrs ") : "";
    let mDisplay = m > 0 ? m + (m === 1 ? " Min, " : s !== '' ? " Min, " : " Min ") : "";
    let sDisplay = s > 0 ? s + "sec" : "";

    return hDisplay + mDisplay + sDisplay;
}

const convertTimeToMinutes = (time) => {
    let timeArray = time.split(":");
    return (Number(timeArray[0]) * 60) + Number(timeArray[1]);
}

const secondsToMinutes = (seconds) => {
    let minitue = Math.floor(seconds / 60);
    if (minitue > 0) {
        return minitue + ' Min'
    }
    return '';
}

const round2digit = (num) => {
    if (num % 1 != 0) {
        return (+num).toFixed(2);
    } else {
        return parseFloat((num).toFixed(2));
    }
}

module.exports = {
    generateRandomToken: generateRandomToken,
    msToTime: msToTime,
    secondsToHms: secondsToHms,
    convertTimeToMinutes: convertTimeToMinutes,
    stringSecondsToHms: stringSecondsToHms,
    secondsToMinutes: secondsToMinutes,
    round2digit: round2digit
}